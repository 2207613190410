<template>
  <el-row class="flex-grow-1"  type="flex">
    <el-col type="flex" :span="18" class="d-flex flex-column" justify="center" align="middle">
      <img style="width:80%;height:auto;margin:auto;" :src="p404ng"/>
    </el-col>
    <el-col class="d-flex flex-column justify-content-center align-items-center" :span="6">
      <h2>页面找不到了~~</h2>
      <sub>Page Not Found</sub>
    </el-col>
  </el-row>
</template>
<script>
import p404ng from '@/assets/images/error-pages/404.png';

export default {
  data() {
    return {
      p404ng,
    };
  },
};
</script>
